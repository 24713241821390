<template>
    <div :class="keyboardClass" id="keyboardId">
        <!-- <div class="d-flex justify-content-end">
            <button
                class="px-4 py-2 my-1 rounded"
                @click="keyBoardToggle(false)"
            >
                Close
            </button>
        </div> -->
    </div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
    name: "SimpleKeyboard",
    props: {
        keyboardClass: {
            default: "simple-keyboard",
            type: String,
        },
        input: {
            type: String,
        },
        // keyBoardToggle: {
        //     type: Function,
        // },
    },
    data: () => ({
        keyboard: null,
    }),
    mounted() {
        this.keyboard = new Keyboard(this.keyboardClass, {
            onChange: this.onChange,
            onKeyPress: this.onKeyPress,
        });
    },
    methods: {
        onChange(input) {
            this.$emit("onChange", input);
        },
        onKeyPress(button) {
            this.$emit("onKeyPress", button);
            if (button === "{shift}" || button === "{lock}") this.handleShift();
        },
        handleShift() {
            let currentLayout = this.keyboard.options.layoutName;
            let shiftToggle = currentLayout === "default" ? "shift" : "default";

            this.keyboard.setOptions({
                layoutName: shiftToggle,
            });
        },
    },
    watch: {
        input(input) {
            this.keyboard.setInput(input);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
