<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="d-flex align-items-center">
                <div :class="{ gridView: selected === 'dayGridMonth' }">
                    <div @click="changeView('dayGridMonth')" class="border-0 cursor-pointer mb-3">
                        <i class="fa-regular fa-calendar"></i>
                        Month
                    </div>
                    <div v-show="selected === 'dayGridMonth'" class="gridBorder"></div>
                </div>
                <div :class="{ gridView: selected === 'timeGridWeek' }">
                    <div @click="changeView('timeGridWeek')" class="mx-3 mb-3 border-0 cursor-pointer">
                        <i class="fa-regular fa-calendar"></i>
                        Week
                    </div>
                    <div v-show="selected === 'timeGridWeek'" class="gridBorder"></div>
                </div>
                <div :class="{ gridView: selected === 'dayGridDay' }">
                    <div @click="changeView('dayGridDay')" class="border-0 cursor-pointer mb-3">
                        <i class="fa-regular fa-calendar"></i>
                        Day
                    </div>
                    <div v-show="selected === 'dayGridDay'" class="gridBorder"></div>
                </div>
            </div>
            <div>
                <div class="mx-2 border-0 text-dark cursor-pointer" @click="changeView('timeGridDay')">
                    <i class="fa-regular fa-calendar"></i>
                    Today
                </div>
                <div class="mt-3"></div>
            </div>
        </div>
        <Fullcalendar ref="fullCalendar" :options="calendarOptions" />
        <b-modal v-model="showEventModal" @show="onModalShow" hide-footer hide-header noFade :centered="true" v-cloak>
            <div class="d-flex align-items-center justify-content-end">
                <i class="fa fa-close mx-2 cursor-pointer" @click="closeModal"></i>
            </div>

            <div class="d-flex justify-content-between align-items-center">
                <strong>Event Detail</strong>
            </div>
            <p class="text-muted">
                {{ selectedEvent?.title }}
                {{ formatDate(selectedEvent?.start) }}
            </p>
        </b-modal>
    </div>
</template>

<script>
    import Fullcalendar from "@fullcalendar/vue3";
    import DayGridPlugin from "@fullcalendar/daygrid";
    import TimeGridPlugin from "@fullcalendar/timegrid";
    import InteractionPlugin from "@fullcalendar/interaction";
    import ListPlugin from "@fullcalendar/list";
    import moment from "moment";

    export default {
        components: {
            Fullcalendar,
        },
        data() {
            return {
                selected: "dayGridMonth",
                showEventModal: false,
                calendarOptions: {
                    update: false,
                    showEventModal: false,
                    selectedEvent: null,
                    events: [],
                    plugins: [
                        DayGridPlugin,
                        TimeGridPlugin,
                        ListPlugin,
                        InteractionPlugin,
                    ],
                    initialView: "dayGridMonth",
                    eventClick: this.handleEventClick,
                    eventContent: this.renderEventContent,
                    headerToolbar: {
                        right: "prev,next",
                        left: "title",
                    },
                },
            };
        },
        methods: {
            changeView(view) {
                this.selected = view;
                this.$refs.fullCalendar.getApi().changeView(view);
            },
            closeModal() {
                this.showEventModal = false;
            },
            handleEventClick(clickInfo) {
                this.selectedEvent = clickInfo.event;
                this.showEventModal = true;
            },
            onModalShow() {
                if (!this.selectedEvent) {
                    this.selectedEvent = this.eventsData[0];
                }
            },
            formatDate(date) {
                return moment(date).format("dddd, D MMMM . hh:mm A");
            },

            renderEventContent(arg) {
                const randomRed = Math.floor(Math.random() * 256);
                const randomGreen = Math.floor(Math.random() * 256);
                const randomBlue = Math.floor(Math.random() * 256);
                const randomRGBColor = `${randomRed},${randomGreen},${randomBlue}`;
                return {
                    html: `
       <div class="p-2 event-content cursor-pointer overflow-sm-auto border-0 rounded" style="background-color: rgba(${randomRGBColor},0.1); ">
                <div style="color:rgb(${randomRGBColor})">${arg.event.title}</div>
                 <div style="color:rgb(${randomRGBColor})">${moment(
                        arg.event.start
                    ).format("MM DD YYYY")}</div>
                </div>
                  `,
                };
            },
        },
        watch: {
            golfClubData(newValue) {
                this.calendarOptions.events = this.golfClubData?.calendar?.map(
                    (calendar) => {
                        return {
                            id: calendar?.id,
                            title: calendar?.title,
                            start: calendar?.date,
                            end: moment(calendar?.date).toISOString(),
                        };
                    }
                );
            },
        },
        mounted() {
            this.calendarOptions.events = this.golfClubData?.calendar?.map(
                (calendar) => {
                    return {
                        id: calendar?.id,
                        title: calendar?.title,
                        start: calendar?.date,
                        end: calendar?.date,
                    };
                }
            );
        },
    };
</script>

<style lang="scss">
table {
    background: white;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
    overflow-x: auto;
}

.fc-scrollgrid-sync-inner a {
    text-decoration: none !important;
    color: #000000;
}

.event-border {
    border-radius: 50px !important;
}

.fc-event {
    &:hover {
        background: none;
    }
}

button {
    background: white;
    color: black;
    border: 0;
    margin: 10px;
    border-radius: 12px;
}

.fc .fc-button-primary {
    background: white;
    color: black;
    border: 0;
    margin: 10px;
    border-radius: 12px;
}

.fc .fc-button-primary:hover {
    background: white;
    color: black;
    border: 0;
    margin: 10px;
    border-radius: 12px;
}

.gridView {
    color: #3abb82 !important;
}

.gridBorder {
    border-bottom: 2px solid #3abb82 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.fc-scroller {
    overflow-x: auto;
}
</style>
