<template>
    <div class="bottom-ticker d-flex justify-content-start align-items-center" v-if="this.golfClubData?.scroll_bar_content?.length">
        <div class="position-fixed">
            <div class="ticker-label">Club News</div>
        </div>
        <div class="ticker-div-container">
            <Vue3Marquee :duration="30">
                <div :class="{ 'ticker': !clickedTicks[index], 'ticker-active': clickedTicks[index] }" class="mx-1"
                    v-for="(tick, index) in this.golfClubData?.scroll_bar_content" :key="index">
                    <div>
                        {{ tick?.message }}
                    </div>
                </div>
            </Vue3Marquee>
        </div>
    </div>
</template>

<script>
    import { Vue3Marquee } from "vue3-marquee";

    export default {
        data() {
            return {
                clickedTicks: {},
                fakeData: [{ "message": "Call club office on 01428 723785 with any changes required to this display" }],
            };
        },
        components: {
            Vue3Marquee,
        },

        created() {
            this.clickedTicks[-1] = true;
        },
        methods: {
            toggleTicker(index) {
                Object.keys(this.clickedTicks).forEach((key) => {
                    this.clickedTicks[key] = false;
                });
                this.clickedTicks[index] = true;
            },
        },
    };
</script>

<style scoped lang="scss">
.bottom-ticker {
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid #d4d4d4;
    box-shadow: 0px -8px 22px 0px #0000000a;
    background: #ffffff;
    padding: 8px 10px;
    z-index: 2;
    overflow: hidden;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    .ticker-div-container {
        margin-left: 7rem;
        width: 100%;
    }

    .vue3-marquee {
        mask-image: linear-gradient(90deg, transparent 0.2%, rgba(0, 0, 0, 1) 1%, rgba(0, 0, 0, 1) 99.2%, transparent 100%)
    }

    .ticker-label {
        background: linear-gradient(to left, #50c299, #acdeaa);
        color: white;
        padding: 6px 12px;
        margin-right: 0px !important;
        cursor: pointer;
        border-radius: 5px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .ticker {
        background: #efefef;
        padding: 5px 12px;
        border-radius: 5px;

        cursor: pointer;

        &-active {
            background: linear-gradient(to left, #50c299, #acdeaa);
            color: white;
            padding: 6px 12px;
            margin-right: 0px !important;
            cursor: pointer;
            border-radius: 5px;
        }
    }

}
</style>
