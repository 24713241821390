<template>
    <BCard class="border border-0 homeCard cursor-pointer" @click="navigateToAwardList()" :class="index == 1 ? 'spacing-homeCard' : 'my-3 my-lg-0'">
        <BCardBody class="text-center">
            <img :src="image" class="mb-5 w-50 homeCard__img" alt="" />
            <BCardTitle class="text-uppercase font-weight-bold homeCard__title mb-4">
                {{ title }}
            </BCardTitle>
            <BButton class="homeCard__btn border border-0" @click="navigateToAwardList()">Enter</BButton>
        </BCardBody>
    </BCard>
</template>
<script>
    import "../assets/scss/index.scss";

    export default {
        props: {
            index: Number,
            title: String,
            description: String,
            image: String,
            length: Number,
        },
        data() {
            return {
                awardCategory: "",
            };
        },
        methods: {
            navigateToAwardList() {
                if (this.length == 2 && this.index == 0) {
                    this.awardCategory = "mens";
                    this.$router.push({
                        path: `/${this.$route.params.board}/awards-list/${this.awardCategory}`,
                    });
                } else if (this.length == 2 && this.index == 1) {
                    this.awardCategory = "ladies";
                    this.$router.push({
                        path: `/${this.$route.params.board}/awards-list/${this.awardCategory}`,
                    });
                } else if (this.length == 1) {
                    this.$router.push({
                        // path: `/${this.$route.params.board}/awards-list/`,
                        name: "Awards List",
                    });
                }
            },
        },
    };
</script>

<style lang="scss">
.spacing-homeCard {
    @media (max-width: 575px) {
        margin-bottom: 65px !important;
    }
}
</style>
