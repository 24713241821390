<template>
    <div class="saver-carousel">
        <Carousel :autoplay="7000" :wrapAround="true">
            <Slide v-for="(slide, index) in concate" :key="index">
                <BCard class="screenSaverCard border border-0" v-if="slide.imageFile && slide.name">
                    <div class="position-relative">
                        <div class="d-flex justify-content-center align-items-center">
                            <img :src="slide?.imageFile
                                ? `${baseUrl}${slide.imageFile}`
                                : image
                                " class="screenSaverCard__img" alt="" />
                        </div>
                        <div class="screenSaverCard__top-content d-flex justify-content-center align-items-center fw-bold px-2 px-lg-auto">
                            Sponsor
                        </div>
                    </div>
                    <BCardBody class="screenSaverCard__body mx-md-4 mx-2 my-5">
                        <div class="d-flex align-items-center screenSaverCard__body__subTitle">
                            {{ slide?.name }}
                        </div>
                    </BCardBody>
                </BCard>
                <!-- award winner -->
                <BCard class="screenSaverCard border border-0" v-else>
                    <div class="position-relative">
                        <div class="d-flex justify-content-center align-items-center">
                            <img :src="slide?.personImg
                                ? `${baseUrl}${slide.personImg}`
                                : image
                                " class="screenSaverCard__img" alt="" />
                        </div>
                        <div class="screenSaverCard__top-content d-flex justify-content-center align-items-center fw-bold px-2 px-lg-auto">
                            {{ slide?.awardName }}
                        </div>
                        <div class="screenSaverCard__award">
                            <img :src="cupimg" class="w-full screenSaverCard__award__img" alt="" />
                        </div>
                    </div>
                    <BCardBody class="screenSaverCard__body mx-md-4 mx-2 my-5">
                        <div class="d-flex align-items-center screenSaverCard__body__subTitle">
                            <span>
                                {{ slide?.year }}
                            </span>
                            <span class="mx-2 screenSaverCard__body__subTitle__dot">
                            </span>
                            <span class="screenSaverCard__body__name">
                                {{ slide?.name }}
                                <!-- D Spencer Vs E & W Hendred (f) -106* -->
                            </span>
                        </div>
                    </BCardBody>
                </BCard>
            </Slide>
            <template #addons>
                <Navigation />
            </template>
        </Carousel>
    </div>
</template>

<script>
    import "@/assets/scss/index.scss";
    import { Carousel, Navigation, Slide } from "vue3-carousel";
    import cupimg from "@/assets/svgs/cupimg.svg";
    import ScreenSaverCard from "@/components/ScreenSaverCard.vue";
    import avatar from "@/assets/imgs/Avatar.png";
    export default {
        props: {
            concate: Array,
        },
        components: {
            ScreenSaverCard,
            Carousel,
            Navigation,
            Slide,
        },
        data() {
            return {
                searchterm: "",
                cupimg,
                image: avatar,
            };
        },

        watch: {
            $route(to, from, next) {
                this.searchterm = "";
            },
        },
    };
</script>

<style lang="scss">
.saver-carousel {
    .carousel__next {
        display: none;
    }

    .carousel__prev {
        display: none;
    }
}
</style>
