import { createRouter, createWebHistory } from "vue-router";
import ClubOfficials from "../views/ClubOfficials.vue";
import Calendar from "../views/Calendar.vue";
import Gallery from "../views/Gallery.vue";
import Sponsors from "../views/Sponsors.vue";
import News from "../views/News.vue";
import Search from "../views/Search.vue";
import Settings from "../views/Settings.vue";
import ScreenSaver from "../views/ScreenSaver.vue";
import WinnersAwards from "../views/WinnersAwards.vue";
import AwardsList from "../views/AwardsList.vue";
import Index from "../views/Index.vue";
import Test from "../views/test.vue";

const routes = [
    {
        path: "/:board/",
        component: Test,
        children: [
            {
                path: "",
                name: "honours",
                component: Index,
            },

            {
                path: "screen-saver",
                name: "Screen Saver",
                component: ScreenSaver,
            },
            {
                path: "winners-awards/:id",
                name: "Winners Awards",
                component: WinnersAwards,
            },
            {
                path: "club-officials",
                name: "club-officials",
                component: ClubOfficials,
            },
            {
                path: "calendar",
                name: "calendar",
                component: Calendar,
            },
            {
                path: "calendar",
                name: "calendar",
                component: Calendar,
            },
            {
                path: "gallery",
                name: "gallery",
                component: Gallery,
            },
            {
                path: "sponsors",
                name: "sponsors",
                component: Sponsors,
            },
            {
                path: "awards-list/:awardCategory",
                name: "Award List",
                component: AwardsList,
            },
            {
                path: "awards-list",
                name: "Awards List",
                component: AwardsList,
            },

            {
                path: "news",
                name: "news",
                component: News,
            },
            {
                path: "search",
                name: "Search",
                component: Search,
            },
            {
                path: "settings",
                name: "settings",
                component: Settings,
            },
        ],
    },
    {
        path: "/",
        component: Test,
        redirect: "/Merryford-Golf-Club1983",
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
export default router;
