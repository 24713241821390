<template>
    <div class="saver-carousel">
        <Carousel :autoplay="15000" :wrap-around="true">
            <Slide v-for="slide in findRecipientsWithFallback" :key="slide">
                <div class="carousel__item">
                    <BCard class="screenSaverCard border border-0">
                        <div class="position-relative">
                            <div class="d-flex justify-content-center align-items-center">
                                <img :src="slide?.personImg
                                    ? `${baseUrl}${slide.personImg}`
                                    : avatar
                                    " class="screenSaverCard__img" alt="" />
                            </div>
                            <div class="screenSaverCard__top-content">
                                <p class="screenSaverCard__top-content__text mx-2">
                                    {{ slide?.awardName }}
                                </p>
                            </div>
                            <div class="screenSaverCard__award">
                                <img :src="cupimage" class="w-full screenSaverCard__award__img" alt="" />
                            </div>
                        </div>
                        <BCardBody class="screenSaverCard__body mx-md-5 mx-2 my-3">
                            <div>
                                <span class="screenSaverCard__body__name">
                                    {{ slide?.name }}
                                </span>
                                <div class="d-flex align-items-center screenSaverCard__body__subTitle">
                                    <span>
                                        {{ slide?.year }}
                                    </span>
                                </div>
                            </div>
                        </BCardBody>
                    </BCard>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
            </template>
        </Carousel>
    </div>
</template>

<script>
    import "@/assets/scss/index.scss";
    import { Carousel, Navigation, Slide } from "vue3-carousel";
    import cupimg from "@/assets/svgs/cupimg.svg";
    import ScreenSaverCard from "@/components/ScreenSaverCard.vue";
    import avatar from "@/assets/imgs/Avatar.png";
    export default {
        components: {
            ScreenSaverCard,
            Carousel,
            Navigation,
            Slide,
        },
        data() {
            return {
                image: avatar,
                cupimage: cupimg,
                currentSaverIndex: 0,
                showWelcomeText: false,
                currentRecipientIndex: 0,
                searchterm: "",
            };
        },

        watch: {
            $route(to, from, next) {
                this.searchterm = "";
            },
        },

        methods: {
            findRecipientsByYear(targetYear) {
                const recipients = [];
                if (this?.golfClubData?.awards?.length > 0) {
                    for (const award of this?.golfClubData?.awards) {
                        for (const person of award.persons) {
                            const year = person?.date?.includes("/")
                                ? person?.date.split("/")[0]
                                : person?.date;
                            if (parseInt(year) === targetYear) {
                                recipients.push({
                                    name: person?.name,
                                    year: person?.date,
                                    personImg: person.image,
                                    awardName: award?.name,
                                });
                            }
                        }
                    }
                    return recipients;
                }
            },
            getMinDate() {
                const awards = this?.golfClubData?.awards;
                if (!awards || awards.length === 0) {
                    return null;
                }
                let minDate = Infinity;
                for (const personsObj of awards) {
                    for (const data of personsObj.persons) {
                        const date = data.date;
                        if (date < minDate) {
                            minDate = date;
                        }
                    }
                }
                return minDate;
            },
        },

        computed: {
            findRecipientsWithFallback() {
                let targetYear = new Date().getFullYear();

                while (targetYear >= this.getMinDate()) {
                    const recipients = this.findRecipientsByYear(targetYear);
                    if (recipients && recipients?.length > 0) {
                        return recipients;
                    }
                    targetYear--;
                }

                return [];
            },

            isClubOfficialsRoute() {
                return this.$route.path === "/club-officials";
            },
        },
        beforeUnmount() {
            clearTimeout(this.idleTimer);
        },
    };
</script>
<style lang="scss" scoped>
.settings__modal {
    .modal-backdrop {
        --bs-backdrop-bg: #b4b4b4 !important;
        --bs-backdrop-opacity: 60% !important;
    }

    .modal-content {
        background: transparent !important;
        border-radius: 2rem !important;
        margin: auto !important;
        border: none !important;

        .close__img {
            position: absolute;
            top: -9%;
            right: 5%;
            cursor: pointer;

            @media (max-width: 575px) {
                top: -13%;
            }
        }
    }

    @media (max-width: 575px) {
        .modal-body {
            padding: 0px !important;
        }
    }
}

.carousel__next {
    margin-right: -45px !important;

    @media (max-width: 600px) {
        margin-top: 180px !important;
        margin-right: 0px !important;
    }

    background: linear-gradient(90deg, #baf8b7 0%, #50c299 100%),
    linear-gradient(0deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.4));

    border-radius: 12px;
    box-sizing: content-box;
    width: 22.736839294433594px !important;
    height: 22.736839294433594px !important;
    padding: 3.789459228515625px 6.682079315185547px 3.789486885070801px 6.68212890625px;
}

.carousel__prev {
    margin-left: -45px !important;

    @media (max-width: 650px) {
        margin-top: 180px !important;
        margin-left: 0px !important;
    }

    background: linear-gradient(90deg, #baf8b7 0%, #50c299 100%),
    linear-gradient(0deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.4));

    border-radius: 12px;
    box-sizing: content-box;
    width: 22.736839294433594px !important;
    height: 22.736839294433594px !important;
    padding: 3.789459228515625px 6.682079315185547px 3.789486885070801px 6.68212890625px;
}

.gallery__modal {
    .modal-backdrop {
        --bs-backdrop-bg: rgb(98, 98, 99) !important;
        --bs-backdrop-opacity: 80% !important;
    }

    .close__img {
        text-align: right;

        @media (min-width: 1100px) {
            position: absolute;
            top: -5%;
            right: -60%;
            display: flex;
            cursor: pointer;
            width: 48px;
            height: 48px;
            background: rgba(67, 70, 77, 1);
            border-radius: 50%;
            justify-content: center;
            align-items: center;
        }
    }

    .modal-content {
        border-radius: 2rem !important;
        margin: auto !important;
        border: none !important;
        background-color: transparent;
    }
}

.winnerAward-modal {
    .modal-content {
        background-color: #f6f6f6 !important;
        border-radius: 17px;
    }
}

.club_modal {
    .modal-content {
        background: rgba(30, 31, 32, 1) !important;
        border-radius: 0 !important;
    }

    .card {
        border: none !important;
        background: transparent !important;
    }

    .card-title {
        color: white;
    }

    .card-text {
        color: white;
    }

    .club_sidebox-card_img {
        width: 108px !important;
        height: 108px !important;
        border-radius: 12px;
    }

    .closeIcon {
        cursor: pointer;
    }
}

.awardList-carousel {
    .carousel__next {
        margin-right: -50px !important;

        @media screen and (min-width: 575px) and (max-width: 900px) {
            margin-top: 150px !important;
            margin-right: 0px !important;
        }

        @media (max-width: 575px) {
            margin-top: 130px !important;
            margin-right: 0px !important;
        }

        background: rgba(255, 255, 255, 1);
        border-radius: 9px;

        padding: 18px,
        21px,
        18px,
        21px;
        width: 20.67px !important;
        height: 20.7px !important;
    }

    .carousel__prev {
        margin-left: -50px !important;

        @media screen and (min-width: 575px) and (max-width: 900px) {
            margin-top: 150px !important;
            margin-left: 0px !important;
        }

        @media (max-width: 575px) {
            margin-top: 130px !important;
            margin-left: 0px !important;
        }

        background: rgba(255, 255, 255, 1);
        border-radius: 9px;

        padding: 18px,
        21px,
        18px,
        21px;
        width: 20.67px !important;
        height: 20.7px !important;
    }
}

.saver-carousel {
    .carousel__next {
        display: none;
    }

    .carousel__prev {
        display: none;
    }
}
</style>
