<template>
    <div>
        <div class="container news__block__card cursor-pointer">
            <div class="news__block__card__imgBox d-flex position-relative justify-content-center">
                <button class="news__block__card__imgBox-btn text-white border-0 position-absolute">
                    Sponsor
                </button>
                <ImageWithFallback :src="image" class="news__block__card__imgBox-img"/>
            </div>

            <div class="d-flex justify-content-between align-items-center pt-4 pt-lg-3 pb-0 pb-lg-2">
                <div class="d-flex align-items-center">
                    <p class="news__block__card__imgBox-text mb-0">
                        {{ headingText }}
                    </p>
                </div>
                <button class="news__block__card__imgBox-icon">
                    <i class="fa-solid fa-chevron-right text-muted px-1"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import ImageWithFallback from "@/components/ImageWithFallback.vue";
    
    export default {
        props: { btnText: String, image: String, headingText: String, logo: String },
        components: {
            ImageWithFallback,
        },
    }
</script>
