<template>
    <h2 class="text-center text-md-start">Awards</h2>
    <BRow class="py-3 mx-2 mx-lg-5 mb-4">
        <BCol>
            <AwardsListCarousel />
        </BCol>
    </BRow>
    <BRow>
        <BCol lg="4" class="mx-0 px-2 my-2" v-if="CommunityDetails !== null">
            <CommunityCard :data="CommunityDetails" />
        </BCol>
        <BCol lg="8">
            <BRow class="d-flex winnerAwards">
                <BCol v-for="(obj, index) in awardData" :key="index" lg="4" xl="4" class="col-6 my-2 my-lg-0">
                    <WinnersAwardsCards :data="obj" @click="handleAwardWinners(obj)" :pageType="'awardList'" />
                </BCol>
            </BRow>
        </BCol>
    </BRow>
</template>

<script>
    import WinnersAwardsCards from "@/components/WinnersAwardsCards.vue";
    import AwardsListCarousel from "@/components/AwardsListCarousel.vue";

    import CommunityCard from "@/components/CommunityCard.vue";

    export default {
        components: {
            WinnersAwardsCards,
            AwardsListCarousel,
            CommunityCard,
        },

        data() {
            return {
                awardData: [],
                selectedAward: null,
                CommunityDetails: null,
                category: "",
            };
        },
        methods: {
            handleAwardWinners(obj) {
                this.selectedAward = obj;
                this.$router.push({
                    path: `/${this.$route.params.board}/winners-awards/${obj.id}`,
                });
            },
        },

        created() {
            const category = this.$route.params.awardCategory;
            this.category = category;
            if (this.category && this.category !== "") {
                this.awardData = this.golfClubData?.awards.filter(
                    (award) => award[this.category] === true
                );
            } else {
                this.awardData = this.golfClubData?.awards;
            }
        },
        watch: {
            golfClubData() {
                if (this.category && this.category !== "") {
                    this.awardData = this.golfClubData?.awards.filter(
                        (award) => award[this.category] === true
                    );
                } else {
                    this.awardData = this.golfClubData?.awards;
                }
                this.CommunityDetails = {
                    imageFile: this.golfClubData.backgroundImage,
                    name: this.golfClubData?.name,
                    extra: this.golfClubData?.info,
                };
            },
            category() {
                if (
                    this.golfClubData &&
                    this.golfClubData?.awards &&
                    this.golfClubData?.awards.length > 0
                ) {
                    if (this.category && this.category !== "") {
                        this.awardData = this.golfClubData?.awards.filter(
                            (award) => award[this.category] === true
                        );
                    } else {
                        this.awardData = this.golfClubData?.awards;
                    }
                    this.CommunityDetails = {
                        imageFile: this.golfClubData?.backgroundImage,
                        name: this.golfClubData?.name,
                        extra: this.golfClubData?.info,
                    };
                }
            },
        },
        mounted() {
            let mainContent = document.getElementById("main-content");
            mainContent.scrollTo(0, 0);
            if (
                this.golfClubData &&
                this.golfClubData?.awards &&
                this.golfClubData?.awards.length > 0
            ) {
                if (this.category) {
                    this.awardData = this.golfClubData?.awards.filter(
                        (award) => award[this.category] === true
                    );
                } else {
                    this.awardData = this.golfClubData?.awards;
                }
                this.CommunityDetails = {
                    imageFile: this.golfClubData?.backgroundImage,
                    name: this.golfClubData?.name,
                    extra: this.golfClubData?.info,
                };
            }
        },
    };
</script>

<style></style>
