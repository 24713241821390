<template>
    <div>
        <h2 class="mb-3 text-center text-md-start">Settings</h2>
        <BRow class="d-flex">
            <BCol v-for="(card, index) in SettingsData" :key="index" class="col-12 col-md-6 mb-md-2 mb-lg-4 col-xl-3">
                <BCard class="my-2 my-lg-0 settingsCard" @click="
                    selectedCard != card && card.title != 'Refresh Board'
                        ? selectCard(card)
                        : card.title == 'Refresh Board'
                            ? this.refreshPage()
                            : selectCard({ sub_title: '', src: '' })
                    " :class="{ settingsCard__selected: selectedCard === card }">
                    <BCardBody class="text-center">
                        <img :src="card.src" class="h-auto settingsCard__img" alt="" />
                        <BCardTitle class="font-weight-bold settingsCard__title">{{ card.title }}</BCardTitle>
                        <BCardText class="settingsCard__description">
                            {{ card.description }}
                        </BCardText>
                    </BCardBody>
                </BCard>
            </BCol>
        </BRow>
        <BRow>
            <SettingsCard :selectedCard="selectedCard" @reset-selected-card="resetSelectedCard" />
        </BRow>
    </div>
</template>

<script>
    import SettingsCard from "@/components/SettingsCard.vue";
    import SettingsData from "../constants/SettingsData";
    export default {
        data() {
            return {
                SettingsData,
                selectedCard: {
                    sub_title: "",
                    src: "",
                },
            };
        },
        components: {
            SettingsCard,
        },
        methods: {
            selectCard(card) {
                this.selectedCard = card;
            },
            refreshPage() {
                console.log("refresh");
                window.location.reload();
            },
            resetSelectedCard() {
                this.selectedCard = {
                    sub_title: "",
                    src: "",
                };
            },
        },
    };
</script>
