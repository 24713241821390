<template>
    <div class="d-md-flex align-items-md-center winner__card my-2 cursor-pointer" @click="toggle ? singleUserData(data) : null">
        <div class="winner__avatar mx-0 mx-lg-2">
            <div>
                <div v-if="pageType === 'awardList'">
                    <b-avatar v-if="data?.imageFile" variant="" :src="`${baseUrl}${data.imageFile}`" size="2.5rem"></b-avatar>
                    <b-avatar v-else variant="" :src="trophy" size="2.5rem"></b-avatar>
                </div>
                <div v-else>
                    <b-avatar v-if="data?.image" variant="" :src="`${baseUrl}${data.image}`" size="2.5rem"></b-avatar>
                    <b-avatar v-else variant="" :src="profileAvatar" size="2.5rem"></b-avatar>
                </div>
            </div>
        </div>
        <div class="winner__details d-lg-flex justify-content-between align-items-center w-100">
            <div class="winner__details-profile my-2 my-lg-0">
                <div>
                    <span class="winnerAwards__card__level">
                        {{ getDate(data) }}
                    </span>
                    <h6 class="winnerAwards__card__name mb-0">
                        {{ data?.name }}
                    </h6>
                </div>
            </div>
            <div class="winner__details-medal rounded px-2 py-1">
                <div v-if="pageType !== 'awardList'">
                    <img :src="medal" alt="" />
                </div>
                <div v-else></div>
            </div>
        </div>
    </div>
</template>

<script>
    import "../assets/scss/index.scss";
    import trophy from "@/assets/imgs/trophy.png";
    import person from "@/assets/imgs/person-1.png";
    import profileAvatar from "@/assets/imgs/profile-avatar.png";
    import medal from "@/assets/svgs/medal.svg";

    export default {
        props: {
            data: Object,
            toggle: Function,
            singleUserData: Function,
            pageType: String,
        },
        data() {
            return {
                trophy,
                person,
                profileAvatar,
                medal,
            };
        },
        methods: {
            getDate(data) {
                return (data?.date
                    ? data?.date?.includes("/")
                        ? data?.date.split("/")[0]
                        : data?.date
                    : "");
            }
        },
        computed: {

        }
    };
</script>
<style lang="scss"></style>
