<template>
    <div class="awardList-carousel">
        <Carousel v-bind="settings" :breakpoints="breakpoints" :wrap-around="true">
            <Slide v-for="(slide, index) in findRecipientsWithFallback" :key="index">
                <div class="carousel__item">
                    <b-avatar class="carousel__item__img bg-white mb-2" v-b-avatar-img-fallback
                        :src="slide?.personImg ? `${baseUrl}${slide?.personImg}` : defaultImg"></b-avatar>
                    <div class="carousel__item__name">
                        <span>{{ slide?.name }} </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-center carousel__item__contents">
                        <span class="carousel__item__date text-start">
                            {{ slide?.year }}
                        </span>
                        <span class="carousel__item__dot mx-1"> </span>
                        <span class="carousel__item__title text-start">
                            {{ slide?.awardName }}
                        </span>
                    </div>
                    <div class="d-flex justify-content-center align-items-center mt-3">
                        <div>
                            <img :src="awardlist1" style="height:35px" />
                        </div>
                        <div>
                            <img :src="awardlist2" style="height:35px" />
                        </div>
                    </div>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
            </template>
        </Carousel>
    </div>
</template>

<script>
    import { Carousel, Slide, Navigation } from "vue3-carousel";

    import profileAvatar from "@/assets/imgs/profile-avatar.png";
    import awardlist1 from "../assets/imgs/awardlist1.png";
    import awardlist2 from "../assets/imgs/awardlist2.png";

    export default {
        name: "AwardsListCarousel",
        components: {
            Carousel,
            Slide,
            Navigation,
        },
        data() {
            return {
                personData: [],
                defaultImg: profileAvatar,
                awardlist1: awardlist1,
                awardlist2: awardlist2,

                settings: {
                    itemsToShow: 5,
                    snapAlign: "center",
                },
                breakpoints: {
                    // Small screens (up to 700px width)
                    700: {
                        itemsToShow: 4,
                        snapAlign: "center",
                    },
                    // Medium screens (from 701px to 1023px width)
                    999: {
                        itemsToShow: 5,
                        snapAlign: "center",
                    },
                    // Large screens (1024px and above)
                    1300: {
                        // itemsToShow: 7.2,
                        perPage: 6,
                        snapAlign: "center",
                    },
                    320: {
                        itemsToShow: 2,
                        snapAlign: "center",
                    },
                },
            };
        },

        methods: {
            findRecipientsByYear(targetYear) {
                return (this.golfClubData?.awards || [])
                    .flatMap(award =>
                        award.persons.filter(person => {
                            const year = person.date?.split("/")[0];
                            return parseInt(year) === targetYear;
                        })
                            .map(person => ({
                                name: person.name,
                                year: person.date,
                                personImg: person.image,
                                awardName: award.name,
                            }))
                    );
            },
            getMinDate() {
                const awards = this?.golfClubData?.awards;
                if (!awards || awards.length === 0) {
                    return null;
                }
                let awardYears = awards
                    .flatMap(personsObj => personsObj.persons.map(data => Number(data.date))).filter(Boolean) || [];
                return Math.min(...awardYears);
            },
        },
        computed: {
            findRecipientsWithFallback() {
                const currentYear = new Date().getFullYear();
                const minDate = this.getMinDate() || currentYear;
                let recipients = [];

                //If we have enough recipients in the current year, show them.
                let thisYearsRecipients = this.findRecipientsByYear(currentYear);
                if (this.findRecipientsByYear(currentYear) > 6) {
                    return thisYearsRecipients;
                }

                //If we don't have enough recipients to fill the slider, go back through all the years.
                for (let targetYear = currentYear; targetYear >= minDate; targetYear--) {
                    let yearRecipients = this.findRecipientsByYear(targetYear);
                    recipients = recipients.concat(yearRecipients);
                }

                //Limit the output to a max of 50 items for performance reasons.
                return recipients.slice(0, 50);
            },
        },
    };
</script>

<style lang="scss" scoped>
.carousel__item {
    display: block;
    border: 2px solid #8ce1aa;
    align-items: center;
    justify-content: center;
    min-height: 238px;
    width: 100%;

    @media screen and (max-width: 1400px) {
        padding: 12px !important;
    }

    @media screen and (max-width: 1300px) {
        padding: 5px !important;
    }

    @media screen and (max-width: 1100px) {
        padding: 5px !important;
    }

    @media (max-width: 575px) {
        padding: 5px;
        height: 210px;
    }

    background: rgba(255, 255, 255, 1);

    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);

    padding: 18px;
    border-radius: 16px;

    position: relative;
    z-index: 1999;

    &__img {
        width: 84px;
        height: 84px;

        @media screen and (min-width: 320px) and (max-width: 990px) {
            width: 60px;
            height: 60px;
        }
    }

    &__name {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        text-align: center;

        @media screen and (min-width: 320px) and (max-width: 990px) {
            font-size: 13px;
            font-weight: 600;
        }

        @media screen and (max-width: 1300px) {
            font-size: 15px !important;
        }

        @media screen and (max-width: 1100px) {
            font-size: 12px !important;
        }
    }

    &__date {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;

        @media screen and (min-width: 320px) and (max-width: 990px) {
            font-size: 11px;
        }
    }

    &__dot {
        width: 3px;
        height: 3px;
        background: rgba(128, 131, 163, 1);
    }

    &__title {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;

        @media screen and (min-width: 320px) and (max-width: 990px) {
            font-size: 11px;
        }

        @media screen and (max-width: 1300px) {
            font-size: 12px !important;
        }

        @media screen and (max-width: 1100px) {
            font-size: 12px !important;
        }
    }

    &__contents {
        @media screen and (max-width: 1024.99px) {
            display: block !important;
        }

        @media screen and (max-width: 768.99px) {
            display: block !important;
        }
    }
}

.carousel__slide {
    padding: 10px 5px 10px 5px;
}



.carousel__slide--sliding {
    transition: 0.5s;
}

.carousel__slide {
    transform: rotateY(0) scale(0.9);
    opacity: 1;
}

.carousel__slide--active~.carousel__slide {
    transform: rotateY(-10deg) scale(0.9);

    @media (max-width: 575px) {
        transform: rotateY(0) scale(0.9);
    }
}

.carousel__slide--prev {
    opacity: 1;
    transform: rotateY(0) scale(0.9);
}

.carousel__slide--next {
    opacity: 1;
    transform: rotateY(0) scale(0.9);
}

.carousel__slide--active {
    opacity: 1;
    transform: rotateY(0) scale(1);
    position: relative;
    z-index: 2999;
}
</style>
