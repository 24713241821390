// store/modules/golfClub.js
import { RepositoryFactory } from "@/repository/RepositoryFactory";

const golfClubRepository = RepositoryFactory.get("golfClub");

const state = {
    golfClubData: {},
    personAwards: {},
    showSidebar: false,
};
const getters = {
    golfClubData: (state) => state.golfClubData,
    personAwards: (state) => state.personAwards,
    showSidebar: (state) => state.showSidebar,
};

const mutations = {
    setGolfClub(state, payload) {
        state.golfClubData = payload;
    },
    setPersonAwards(state, payload) {
        state.personAwards = payload;
    },
    setSidebarState(state, payload) {
        state.showSidebar = payload;
    },
};

const actions = {
    async getGolfClub({ commit }, { board }) {
        try {
            let { data } = await golfClubRepository.getGolfClub(board);
            commit("setGolfClub", data);
        } catch (error) {
            alert("Invalid Board");
            console.error("error axios", error, board);
            commit("setGolfClub", []);
        }
    },
    async getPersonAwards({ commit, state }, { personName, onSuccess }) {
        try {
            let dataAward = [];
            state.golfClubData?.awards?.forEach((award) => {
                award?.persons.forEach((person, index, tarray) => {
                    if (
                        person.name
                            .toLowerCase()
                            .includes(personName.toLowerCase())
                    ) {
                        dataAward.push({
                            name: award.name,
                            date: person.date,
                            extra: person.extra,
                            awardImage: award.imageFile,
                        });
                    }
                });
            });

            commit("setPersonAwards", dataAward);
            onSuccess();
        } catch (error) {
            alert(error);
            console.error(error);
            commit("setPersonAwards", []);
        }
    },
    toggleSidebar({ commit, state }) {
        commit("setSidebarState", !state.showSidebar);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
