import axios from "axios";
const baseDomain = "https://admin.creativehonourboards.co.uk";
const baseURL = `${baseDomain}`;

let axiosObj;
axiosObj = axios.create({
    baseURL,
    headers: {},
});

export default axiosObj;
