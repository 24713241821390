import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            baseUrl: "https://admin.creativehonourboards.co.uk",
            selectedCheckBox: [],
            myrules: {
                required: (value) => !!value || "Required.",
            },
        };
    },

    computed: {
        ...mapGetters("GolfClub", ["golfClubData"]),
        ...mapGetters("GolfClub", ["personAwards"]),
        ...mapGetters("GolfClub", ["showSidebar"]),
    },

    mounted() {},
    methods: {
        ...mapActions("GolfClub", ["getGolfClub"]),
        ...mapActions("GolfClub", ["getPersonAwards"]),
        ...mapActions("GolfClub", ["toggleSidebar"]),
    },
};
