<template>
    <div class="club_sidebox py-3 px-2">
        <BCard class="club_sidebox-card p-2" style="background: transparent">
            <div
                class="position-relative d-flex justify-content-center align-items-center"
            >
                <img
                    :src="`${this.baseUrl}${selectedCard?.imageFile}`"
                    class="club_sidebox-card_img"
                />
            </div>
            <BCardBody
                class="text-center mb-4 d-flex flex-column justify-content-center align-items-center pb-0"
            >
                <BCardTitle class="font-weight-bold club_sidebox-card_title">{{
                    selectedCard?.name
                }}</BCardTitle>
                <!-- <BCardText class="mb-2 club_sidebox-card_username">
                    @edwardford
                </BCardText>
                <BCardText class="club_sidebox-card_dates">
                    2000 - 2021
                </BCardText> -->
            </BCardBody>
            <!-- <BCardText
                class="bottom_text d-flex flex-column justify-content-start align-items-start"
                ><span class="club_sidebox-card_label">Email</span
                ><span class="club_sidebox-card_text">
                    {{ selectedCard.email }}
                </span></BCardText
            > -->
            <!-- <BCardText
                class="bottom_text d-flex flex-column justify-content-start align-items-start"
                ><span class="club_sidebox-card_label">Address</span
                ><span class="club_sidebox-card_text">
                    {{ selectedCard.address }}
                </span></BCardText
            > -->
            <!-- <BCardText
                class="bottom_text d-flex flex-column justify-content-start align-items-start"
                ><span class="club_sidebox-card_label">Phone</span
                ><span class="club_sidebox-card_text">
                    {{ selectedCard.phone }}
                </span></BCardText
            > -->
            <BCardText
                class="bottom_text d-flex flex-column justify-content-start align-items-start"
                ><span class="club_sidebox-card_label">Bio</span
                ><span class="club_sidebox-card_bio"
                    >{{ selectedCard?.extra }}
                </span></BCardText
            >
        </BCard>
    </div>
</template>
<script>
export default {
    props: {
        selectedCard: Object,
    },
};
</script>
<style lang="scss" scoped>
.club_sidebox {
    background: #1e1f20;
    width: 290px !important;
    height: 100vh;
    // padding: 42px 25px;
    padding: 2rem 0 !important;
    position: fixed;
    top: 0;
    right: 0;
    // z-index: 2;
    border-radius: 32px 0px 0px 32px !important;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(143, 146, 161, 1);
        border-radius: 10px;
    }

    &-card {
        background: transparent;
        border: none !important;
        border-radius: 12px;
        cursor: default;
        margin-bottom: 1rem;

        &_img {
            width: 70px;
            height: 70px;
            border-radius: 14px;
        }
        &_title {
            font-size: 25px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0px;
            text-align: center;
            color: #ececed;
        }
        &_username {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            color: #d7d7d9;
        }
        &_dates {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            color: #d3d3d5;
        }
        &_label {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
            color: #8083a3;
        }
        &_text {
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0px;
            text-align: left;
            color: white;
        }
        &_bio {
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0px;
            text-align: left;
            color: rgba(255, 255, 255, 0.84);
        }
    }
}

@media (min-width: 1440px) {
    .club_sidebox {
        width: 300px !important;
    }
}
@media (min-width: 1980px) {
    .club_sidebox {
        width: 370px !important;
    }
}
@media (min-width: 2300px) {
    .club_sidebox {
        width: 600px !important;
    }
}
</style>
