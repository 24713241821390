<template>
    <BRow>
        <BCol class="club col-12 col-xl-12 col-xxl-12">
            <BContainer>
                <h2 class="my-4 text-center text-md-start">Club Officials</h2>
                <BRow class="club-scrollable row row-cols-sm-6">
                    <BCol class="col-6 col-xl-3 col-lg-3 col-md-6 col-sm-6 club__card px-1 px-lg-2" v-for="(card, index) in this.golfClubData?.key_persons"
                        :key="index" @click="handleCardSelection(card)">
                        <BCard :class="{
                            'club-active_card pb-3 p-lg-2 ':
                                card === selectedCard,
                            'club-card pb-3 p-lg-2': card !== selectedCard,
                        }">
                            <BCardBody class="text-center p-0 m-0 d-flex flex-column justify-content-center align-items-center">
                                <div class="position-relative d-flex justify-content-center align-items-center">
                                    <b-avatar class="club-card_img mb-3 bg-white" v-b-avatar-img-fallback
                                        :src="card?.imageFile ? `${this.baseUrl}${card.imageFile}` : profileAvatar"></b-avatar>
                                </div>
                                <BCardTitle class="font-weight-bold club-card_title">{{ card?.name }}</BCardTitle>
                            </BCardBody>
                        </BCard>
                    </BCol>
                </BRow>
            </BContainer>
        </BCol>
        <BModal v-if="selectedCard != null" v-model="modal" hideHeader hideFooter class="club_modal" noFade :centered="true" rounded="true" v-cloak
            @hidden="selectedCard = null">
            <div class="d-flex justify-content-end">
                <img src="../assets/svgs/close.svg" class="closeIcon" @click="modal = false" />
            </div>
            <BCard class="club_sidebox-card p-2">
                <div class="position-relative d-flex justify-content-center align-items-center">
                    <b-avatar class="club_sidebox-card_img mb-3 bg-white" v-b-avatar-img-fallback
                        :src="selectedCard?.imageFile ? `${this.baseUrl}${selectedCard.imageFile}` : profileAvatar"></b-avatar>
                </div>
                <BCardBody class="text-center d-flex flex-column justify-content-center align-items-center pb-0">
                    <BCardTitle class="font-weight-bold club_sidebox-card_title mt-3"><u>{{ selectedCard?.name }}</u></BCardTitle>
                    <BCardTitle class="font-weight-bold club_sidebox-card_title fs-5 mt-1">{{ selectedCard?.extra }}</BCardTitle>
                </BCardBody>
            </BCard>
        </BModal>
    </BRow>
</template>

<script>
    import Header from "../components/Header.vue";
    import profileAvatar from "@/assets/imgs/profile-avatar.png";


    import ClubOfficialBlackCark from "@/components/ClubOfficialBlackCard.vue";
    export default {
        data() {
            return {
                selectedCard: null,
                immediate: false,
                modal: false,
                searchterm: "",
                profileAvatar
            };
        },
        watch: {
            golfClubData: {
                handler(newData) {
                    if (
                        newData &&
                        newData.key_persons &&
                        newData.key_persons.length > 0
                    ) {
                        this.immediate = true;
                    } else {
                        this.selectedCard = null;
                    }
                },
            },
        },
        components: {
            Header,
            ClubOfficialBlackCark,
        },
        methods: {
            handleCardSelection(card) {
                this.selectedCard = card;
                this.modal = true;
            },
            updateIsMobile() {
                this.isMobile = window.innerWidth < 575;
            },
        },
        created() { },
        mounted() {
            // Update isMobile when the window is resized
            window.addEventListener("resize", this.updateIsMobile);
        },
        beforeDestroy() {
            // Remove the event listener when the component is destroyed
            window.removeEventListener("resize", this.updateIsMobile);
        },
    };
</script>

<style lang="scss" scoped>
.club {
    &-card {
        border: none !important;
        border-radius: 12px;
        cursor: pointer;
        margin-bottom: 1rem;
        height: 250px !important;
        width: 100% !important;

        @media (max-width: 575px) {
            width: 100% !important;
            height: 200px !important;
        }

        &_img {
            width: 150px;
            height: 150px;
            border-radius: 14px;

            @media (max-width: 575px) {
                width: 120px !important;
                height: 120px !important;
            }
        }

        &_title {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0px;
            color: #1e1f20;
        }

        &_username {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            color: #7f85a2;
        }

        &_dates {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            color: #7f85a2;
        }
    }

    &-active_card {
        border: none !important;
        background: linear-gradient(90deg, #baf8b7 0%, #50c299 100%);
        height: 250px !important;
        width: 100% !important;

        @media (max-width: 575px) {
            width: 100% !important;
            height: 200px !important;
        }
    }
}

.star {
    position: absolute;
    top: -19%;
    right: 0%;
}

.card-body {
    padding: 0px !important;
}
</style>
