<template>
    <img :src="src ? `${this.baseUrl}${src}` : fallbackImg" @error="fallback"/>
</template>

<script>
import mediaPlaceholder from "@/assets/imgs/gallery-placeholder.svg";
    export default {
        data() {
            return {
                mediaPlaceholder: mediaPlaceholder,
            }
        },
        props: {
            src: String|null,
            fallbackSrc: String|null,
        },
        computed: {
            fallbackImg() {
               return this.fallbackSrc ? fallbackSrc : mediaPlaceholder;
            }
        },
        methods: {
            fallback(e) {
                e.target.src = mediaPlaceholder;
            }
        }
    };
</script>
