<template>
    <BCard class="border border-0 my-lg-0 recentWinnerCard">
        <h3 class="recentWinnerCard__heading">Recent Winners</h3>
        <div class="d-flex align-items-center my-4" v-for="(winner, index) in findRecipientsWithFallback" :key="index">
            <b-avatar class="carousel__item__img bg-white" v-b-avatar-img-fallback
                :src="winner?.personImg ? `${baseUrl}${winner?.personImg}` : profileAvatar"></b-avatar>
            <div class="d-flex flex-column mx-2">
                <span class="fw-bold recentWinnerCard__title">{{ winner.name }}</span>
                <span class="recentWinnerCard__title">{{ winner.awardName }}</span>
            </div>
        </div>
    </BCard>
</template>
<script>
import "../assets/scss/index.scss";

import profileAvatar from "@/assets/imgs/profile-avatar.png";

export default {
    data() {
        return {
            profileAvatar,
            batchSize: 10,
            visibleRecipients: [],
            allRecipients: [],
            loadingInterval: null,
            totalLoadedRecipients: 0,
            isDataReady: false,
        };
    },
    mounted() {
        if (this.golfClubData && this.golfClubData?.awards) {
            this.initializeRecipients();
        }
    },
    methods: {
        initializeRecipients() {
            const currentYear = new Date().getFullYear();
            let thisYearRecipients = this.findRecipientsByYear(currentYear);
            if (thisYearRecipients.length < 6) {
                for (let targetYear = currentYear - 1; targetYear >= this.getMinDate(); targetYear--) {
                    const previousYearRecipients = this.findRecipientsByYear(targetYear);
                    thisYearRecipients = [...thisYearRecipients, ...previousYearRecipients];
                    if (thisYearRecipients.length >= 50) break;
                }
            }

            this.allRecipients = thisYearRecipients;
            this.loadMoreRecipients();
            this.startGradualLoading();
            this.isDataReady = true;
        },

        findRecipientsByYear(targetYear) {
            return (this.golfClubData?.awards || [])
                .flatMap(award =>
                    award.persons
                        .filter(person => {
                            const year = person.date?.split("/")[0];
                            return parseInt(year) === targetYear;
                        })
                        .map(person => ({
                            name: person.name,
                            year: person.date,
                            personImg: person.image,
                            awardName: award.name,
                        }))
                );
        },

        getMinDate() {
            const awards = this?.golfClubData?.awards;
            if (!awards || awards.length === 0) {
                return null;
            }
            let awardYears = awards
                .flatMap(personsObj => personsObj.persons.map(data => Number(data.date))).filter(Boolean) || [];
            return Math.min(...awardYears);
        },

        loadMoreRecipients() {
            const nextBatch = this.allRecipients.slice(this.totalLoadedRecipients, this.totalLoadedRecipients + this.batchSize);
            this.visibleRecipients = [...this.visibleRecipients, ...nextBatch];
            this.totalLoadedRecipients += nextBatch.length;

            // Stop loading when we've loaded all recipients
            if (this.totalLoadedRecipients >= this.allRecipients.length) {
                this.clearLoadingInterval();
            }
        },


        startGradualLoading() {
            this.loadingInterval = setInterval(() => {
                this.loadMoreRecipients();
            }, 200); // Load more items every 200ms
        },

        clearLoadingInterval() {
            if (this.loadingInterval) {
                clearInterval(this.loadingInterval);
                this.loadingInterval = null;
            }
        }
    },
    computed: {
        findRecipientsWithFallback() {
            return this.visibleRecipients;
        }
    },
    beforeDestroy() {
        this.clearLoadingInterval();
    },
};

</script>
<style lang="scss">
.recentWinnerCard {
    .card-body {
        padding-bottom: 0;
    }
}
</style>
