<template>
    <BRow class="py-4 px-3">
        <BCol sm="12" class="col-lg-5">
            <div class="awarded-person-information py-4">
                <BCard class="border-0" img-alt="Image" img-top tag="article" style="max-width: 100%">
                    <b-avatar class="card-img-top bg-white" v-b-avatar-img-fallback
                        :src="personData?.image ? `${baseUrl}${personData?.image}` : this.defaultImage"></b-avatar>
                    <BCardBody class="awarded-person-information__body mx-md-2 mx-2 my-3">
                        <div class="ps-3 py-2 d-flex align-items-left flex-column">
                            <span class="awarded-person-information__body__name">
                                {{ personData?.name }}
                            </span>
                            <div class="awarded-person-information__body__subTitle text-muted mt-3">
                                <span>
                                    {{ personData?.extra }}
                                </span>
                            </div>
                            <div class="mt-3 mb-lg-0 mb-4" @click="toggle">
                                <BButton class="awarded-person-information__body__btn">
                                    Return to Awards
                                </BButton>
                            </div>
                        </div>
                    </BCardBody>
                </BCard>
            </div>
        </BCol>
        <BCol sm="12" class="col-lg-7 my-3 my-lg-0">
            <div class="awarded-person-experience p-4">
                <div>
                    <span class="awarded-person-experience__information">
                        Historical Achievements
                    </span>
                </div>

                <div class="my-2">
                    <div v-for="(obj, index) in this.personAwards" :key="index" class="py-2">
                        <div class="d-block d-flex align-items-center">
                            <div class="awarded-person-experience__img">
                                <img :src="data?.awardImage
                                    ? `${baseUrl}${data.awardImage}`
                                    : this.awardcup
                                    " />
                            </div>

                            <div class="mx-2 d-flex flex-wrap">
                                <div class="d-flex align-items-center gap-1">
                                    <span class="awarded-person-experience__date">{{ obj?.date }}</span>
                                    <div class="awarded-person-experience__dot mx-1"></div>
                                    <span class="awarded-person-experience__title text-muted">{{ obj?.extra }}</span>
                                </div>
                                <div>
                                    <span class="awarded-person-experience__name">
                                        {{ obj?.name }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BCol>
    </BRow>
</template>
<script>
    import awardcup from "../assets/imgs/awardcup.png";

    import profileAvatar from "@/assets/imgs/Avatar.png";

    export default {
        props: {
            personData: Object,
            experienceData: Array,
            toggle: Function,
        },
        data() {
            return {
                awardcup: awardcup,
                defaultImage: profileAvatar,
            };
        },
        methods: {
            awardsPage() {
                this.$router.push({
                    path: `/${this.$route.params.board}/awards-list`,
                });
            },
        },
    };
</script>

<style lang="scss">
.card-img-top {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px !important;
    height: 200px !important;
    margin: auto;
}
</style>
