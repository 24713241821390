<template>
    <!-- :autoplay="2000" -->
    <Carousel :wrap-around="true">
        <Slide v-for="slide in galleryContent" :key="slide">
            <div class="carousel__item">
                <div class="image-wrapper">
                    <ImageWithFallback :src="slide.imageFile" class="carousel__img"/>
                    <div class="image-content">
                        <h3 class="carousel__title">
                            {{ slide.name }}
                        </h3>
                        <p class="carousel__description carousel__truncate-2">
                            {{ slide.extra }}
                        </p>
                    </div>
                </div>
            </div>
        </Slide>
        <template #addons>
            <Navigation />
        </template>
    </Carousel>
</template>

<script>
    import { Carousel, Navigation, Slide } from "vue3-carousel";
    import ImageWithFallback from "@/components/ImageWithFallback.vue";
    export default {
        name: "GalleryCarousel",
        props: { galleryContent: Array, showCarousel: Boolean },
        components: {
            Carousel,
            Slide,
            Navigation,
            ImageWithFallback,
        },
    };
</script>
<style lang="scss" scoped>
.carousel__item {
    width: 100% !important;
    height: auto;

    border-radius: 12px;
}

.carousel__slide {
    padding-left: 10px;
    padding-right: 10px;
}

.carousel {
    &__img {
        width: 100%;
        max-height: 700px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 16px;

        @media (max-width: 775px) {
            height: 322px;
        }
    }

    .image-wrapper {
        position: relative;
    }

    .image-content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        // height: 150px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 0px 0px 16px 16px !important;

        transition: opacity 0.3s ease;
        padding: 25px !important;

        @media (max-width: 775px) {
            // height: 150px;
            padding: 12.775px !important;
        }
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        margin: 0;

        text-align: left !important;

        @media (max-width: 775px) {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
        }
    }

    &__truncate-2 {
        -webkit-line-clamp: 3;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow-wrap: break-word;
    }

    &__description {
        text-align: left !important;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        margin: 0;

        @media (max-width: 775px) {
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .image-wrapper:hover .image-content {
        opacity: 1;
        /* Show the overlay on hover */
    }
}
</style>
