import App from "./App.vue";
import Vue from "vue";

import { createApp } from "vue";
import BootstrapVueNext from "bootstrap-vue-next";

import router from "./router";
import store from "./store";
import global from "./mixins/global";
import ImageFallBack from "./directives/b-avatar-image-fallback";
import LongPress from "./directives/long-press";
import './registerServiceWorker';

const app = createApp(App);
app.mixin(global);
app.use(router);
app.use(store);
app.use(BootstrapVueNext);
app.directive("b-avatar-img-fallback", ImageFallBack);
app.directive("longpress", LongPress);
app.mount("#app"); 
