<template>
    <div>
        <BRow class="d-flex justify-content-center align-items-center">
            <BCol
                class="col-md-8 col-xl-5 d-none d-md-block"
                v-if="selectedCard.sub_title != '' && selectedCard.src != ''"
            >
                <BCard class="settings__inner py-3">
                    <BCardBody class="text-center">
                        <div
                            class="d-flex justify-content-center align-items-center"
                        >
                            <img
                                :src="selectedCard.src"
                                class="h-auto mx-2"
                                alt=""
                            />
                            <BCardTitle class="font-weight-bold">
                                {{ selectedCard.sub_title }}
                            </BCardTitle>
                        </div>
                        <BFormGroup
                            class="my-2 inner__form"
                            v-if="selectedCard.sub_title == 'Board UUID'"
                        >
                            <div
                                class="d-flex flex-column justify-content-start align-items-start"
                            >
                                <label
                                    class="text-uppercase inner__form-label mt-3 mb-2"
                                    >Enter board unique id here</label
                                >
                                <div class="position-relative w-100">
                                    <img
                                        src="../assets//svgs/eye.svg"
                                        alt=""
                                        class="inner__form-eye_icon"
                                    />

                                    <BFormInput
                                        type="text"
                                        placeholder="Unique ID"
                                        class="inner__form-input"
                                    ></BFormInput>
                                </div>
                            </div>
                        </BFormGroup>
                        <BFormGroup class="my-2 inner__form">
                            <div
                                class="d-flex flex-column justify-content-start align-items-start"
                            >
                                <label
                                    class="text-uppercase inner__form-label mt-3 mb-2"
                                    >Enter password here</label
                                >
                                <div class="position-relative w-100">
                                    <img
                                        src="../assets//svgs/eye.svg"
                                        alt=""
                                        class="inner__form-eye_icon"
                                    />

                                    <BFormInput
                                        type="password"
                                        placeholder="Password"
                                        class="inner__form-input"
                                    ></BFormInput>
                                </div>
                            </div>
                            <BButton class="w-100 inner__form-btn py-3"
                                >Connect</BButton
                            >
                        </BFormGroup>
                    </BCardBody>
                </BCard></BCol
            >
        </BRow>

        <!-- Modal component -->

        <BModal
            v-model="showModal"
            v-if="selectedCard.sub_title != '' && selectedCard.src != ''"
            class="d-md-none settings__modal"
            hide-footer
            centered="true"
            hideHeader
            v-cloak
        >
            <img
                src="../assets/svgs/close.svg"
                class="close__img"
                @click="showModal = false"
            />
            <BCard class="settings__inner py-3">
                <BCardBody class="text-center p-0">
                    <div
                        class="d-flex justify-content-center align-items-center"
                    >
                        <img
                            :src="selectedCard.src"
                            class="h-auto mx-2"
                            alt=""
                        />
                        <BCardTitle class="font-weight-bold">
                            {{ selectedCard.sub_title }}
                        </BCardTitle>
                    </div>
                    <BFormGroup
                        class="my-2 inner__form"
                        v-if="selectedCard.sub_title == 'Board UUID'"
                    >
                        <div
                            class="d-flex flex-column justify-content-start align-items-start"
                        >
                            <label
                                class="text-uppercase inner__form-label mt-3 mb-2"
                                >Enter board unique id here</label
                            >
                            <div class="position-relative w-100">
                                <img
                                    src="../assets//svgs/eye.svg"
                                    alt=""
                                    class="inner__form-eye_icon"
                                />

                                <BFormInput
                                    type="text"
                                    placeholder="Unique ID"
                                    class="inner__form-input"
                                ></BFormInput>
                            </div>
                        </div>
                    </BFormGroup>
                    <BFormGroup class="my-2 inner__form">
                        <div
                            class="d-flex flex-column justify-content-start align-items-start"
                        >
                            <label
                                class="text-uppercase inner__form-label mt-3 mb-2"
                                >Enter password here</label
                            >
                            <div class="position-relative w-100">
                                <img
                                    src="../assets//svgs/eye.svg"
                                    alt=""
                                    class="inner__form-eye_icon"
                                />

                                <BFormInput
                                    placeholder="Password"
                                    class="inner__form-input"
                                ></BFormInput>
                            </div>
                        </div>
                        <BButton class="w-100 mt-3 inner__form-btn py-3"
                            >Connect</BButton
                        >
                    </BFormGroup>
                </BCardBody>
            </BCard>
        </BModal>
    </div>
</template>
<script>
export default {
    props: {
        selectedCard: Object,
    },

    data() {
        return {
            showModal: false,
        };
    },

    watch: {
        selectedCard: {
            immediate: true,
            handler(newValue) {
                if (newValue.title != "" && newValue.src != "") {
                    this.showModal = true;
                }
            },
        },
        showModal: {
            handler(newVal) {
                if (!newVal) {
                    this.$emit("reset-selected-card");
                }
            },
        },
    },
};
</script>
