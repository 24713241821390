import profileAvatar from "@/assets/imgs/Avatar.png";
export default {
	beforeMount(el, binding) {
		el = el.querySelector('img');
		try {
			const { value } = binding;
			const fallBackImage = profileAvatar;
			const img = new Image();
			let error = fallBackImage;
			let original = el.src;
			if (typeof value === 'string') {
				error = value;
			}
			if (value instanceof Object) {
				error = value.fallBackImage || fallBackImage;
			}
			img.src = original;
			img.onload = () => {
				el.src = original;
			};
			img.onerror = () => {
				el.src = error;
			};
		} catch (e) {
			console.log(e);
		}
	}
};