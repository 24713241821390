export default {
	mounted(el, binding) {
        if (typeof binding.value.function !== 'function') {
            let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`
            console.warn(warn)
        }

        let pressTimer = null

        let start = (e) => {
            if (e.type === 'click' && e.button !== 0) {
                return;
            }

            if (pressTimer === null) {
                pressTimer = setTimeout(() => {
                    handler()
                }, binding.value.timeout ?? 1000)
            }
        }

        let cancel = (e) => {
            if (pressTimer !== null) {
                clearTimeout(pressTimer)
                pressTimer = null
            }
        }

        const handler = (e) => {
            binding.value.function(e)
        }

        el.addEventListener("mousedown", start);
        el.addEventListener("touchstart", start);

        el.addEventListener("click", cancel);
        el.addEventListener("mouseout", cancel);
        el.addEventListener("touchend", cancel);
        el.addEventListener("touchcancel", cancel);
	}
};