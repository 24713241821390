<template>
    <div>
        <div class="container news__block__card cursor-pointer">
            <div class="news__block__card__imgBox d-flex position-relative">
                <button class="news__block__card__imgBox-btn text-white border-0 position-absolute">
                    News
                </button>

                <img :src="newsImage" class="w-100 news__block__card__imgBox-img" />
            </div>

            <div class="d-flex justify-content-between align-items-center pt-4 pt-lg-3 pb-0 pb-lg-2">
                <div class="d-flex align-items-center">
                    <p class="news__block__card__imgBox-text mb-0">
                        {{ newsText }}
                    </p>
                </div>
                <button class="news__block__card__imgBox-icon">
                    <i class="fa-solid fa-chevron-right text-muted px-1"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            newsBtnText: String,
            newsImage: String,
            newsText: String,
        },
    };
</script>
