<template>
    <BCard class="border border-0 sponsorCard">
        <h3 class="sponsorCard__heading">Sponsors</h3>
        <BRow>
            <BCol
                class="p-0 mt-1 col-md-6 col-6 mt-lg-0"
                v-for="(sponsorCard, index) in this.golfClubData?.sponsors"
                :key="index"
                @click="sponserPage"
            >
                <sponsorsCard
                    :image="sponsorCard.imageFile"
                    :logo="`${this.baseUrl}${sponsorCard.imageFile}`"
                    :headingText="sponsorCard?.name"
                />
            </BCol>
        </BRow>
    </BCard>
</template>
<script>
import sponsorsCard from "@/components/SponsorsCards.vue";

export default {
    components: {
        sponsorsCard,
    },
    data() {
        return {};
    },
    methods: {
        sponserPage() {
            this.$router.push({
                path: `/${this.$route.params.board}/sponsors`,
            });
        },
    },
};
</script>
<style lang="scss"></style>
