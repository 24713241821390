import adminSettings from "@/assets/svgs/adminSettings.svg";
import wifiSettings from "@/assets/svgs/wifiSettings.svg";
import connectBoard from "@/assets/svgs/connectBoard.svg";
import refresh from "@/assets/svgs/refresh.svg";

const SettingsData = [
    {
        title: "Admin settings",
        sub_title: "Admin settings",
        src: adminSettings,
        description: "Access hardware settings - Only with telephone support from CHB",
    },
    {
        title: "Wifi settings",
        sub_title: "Wifi settings",
        src: wifiSettings,
        description: "Access WIFI settings to connect the screen",
    },
    {
        title: "Connect New Board",
        sub_title: "Board UUID",
        src: connectBoard,
        description: "Access to board ID settings - only to be used when requested by CHB",
    },
    {
        title: "Refresh Board",
        sub_title: "Refresh Board",
        src: refresh,
        description: "Manually refresh board content",
    },
];
export default SettingsData;
