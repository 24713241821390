<template>
    <div class="d-flex justify-content-between align-items-center">
        <h2 class="text-start text-md-start mb-0 winnerAwards__title">
            Award Winners
        </h2>
        <div class="return__contents">
            <BButton class="return__contents__btn border border-0" size="lg" @click="this.$router.go(-1)">Back to Awards</BButton>
        </div>
    </div>
    <BRow class="py-3">
        <BRow class="d-flex winnerAwards m-0">
            <BCol v-for="(obj, index) in personsData" :key="index" lg="3" xl="3" class="col-6 mb-2 p-1 p-lg-2">
                <WinnersAwardsCards :data="obj" :toggle="toggle" :singleUserData="singleUserData" :pageType="'winnerAwardsPersons'" />
            </BCol>
        </BRow>
        <div>
            <BButton class="return__contents__btn border border-0 mx-0 mx-lg-3" size="lg" @click="this.$router.go(-1)">Back to Awards</BButton>
        </div>
    </BRow>
    <b-modal v-model="openModal" size="lg" hide-header hide-footer class="winnerAward-modal" noFade :centered="true" v-cloak>
        <div class="position-relative" @click="toggle">
            <div class="winnerAward-modal__close">
                <img :src="closeImg" />
            </div>
        </div>

        <BRow>
            <BCol sm="12">
                <AwardedPersonInformation :personData="this.selectedPersonData" :toggle="toggle" />
            </BCol>
        </BRow>
    </b-modal>
</template>

<script>
    import WinnersAwardsCards from "@/components/WinnersAwardsCards.vue";
    import AwardedPersonInformation from "@/components/AwardedPersonInformation.vue";
    import award from "../assets/svgs/award.svg";
    import close from "../assets/svgs/close.svg";

    export default {
        components: {
            WinnersAwardsCards,
            AwardedPersonInformation,
        },

        methods: {
            singleUserData(personData) {
                console.log("personData", personData);
                this.selectedPersonData = personData;
                this.getPersonAwards({
                    personName: personData.name,
                    onSuccess: () => {
                        this.toggle();
                    },
                });
            },
            toggle() {
                this.openModal = !this.openModal;
            },
        },

        data() {
            return {
                selectedPersonData: {},
                openModal: false,
                dataAward: [],
                closeImg: close,
                personsData: [],
                paramId: "",
            };
        },
        created() {
            this.paramId = this.$route.params.id;
            // setTimeout(() => {
            //     window.scrollTo(0, 0);
            //     alert("Inside Scrool");
            // }, 3000);
        },

        mounted() {
            let mainContent = document.getElementById("main-content");

            mainContent.scrollTo(0, 0);
        },
        watch: {
            paramId(newId) {
                console.log("new id", newId);

                if (
                    this.golfClubData &&
                    this.golfClubData.awards &&
                    this.golfClubData.awards.length > 0
                ) {
                    const selectedAward = this.golfClubData?.awards.find(
                        (award) => {
                            return award.id == newId;
                        }
                    );

                    console.log("selectedAward:", selectedAward);
                    if (selectedAward) {
                        this.personsData = selectedAward.persons.sort(
                            (personA, personB) => {
                                // Assuming awardDate is a property in each person object
                                const dateA = personA?.date?.includes("/")
                                    ? personA?.date.split("/")[0]
                                    : personA?.date;
                                const dateB = personB?.date?.includes("/")
                                    ? personB?.date.split("/")[0]
                                    : personB?.date;
                                new Date(personB.awardDate);

                                // Compare the dates to determine the order
                                return dateB - dateA;
                            }
                        );
                        console.log("this is persons:", this.personsData);
                    }
                }
            },

            golfClubData(newData) {
                let newId = this.paramId;
                if (
                    this.golfClubData &&
                    this.golfClubData.awards &&
                    this.golfClubData.awards.length > 0 &&
                    this.paramId != ""
                ) {
                    const selectedAward = this.golfClubData?.awards.find(
                        (award) => {
                            return award.id == newId;
                        }
                    );

                    console.log("selectedAward:", selectedAward);
                    if (selectedAward) {
                        this.personsData = selectedAward.persons.sort(
                            (personA, personB) => {
                                // Assuming awardDate is a property in each person object
                                const dateA = personA?.date?.includes("/")
                                    ? personA?.date.split("/")[0]
                                    : personA?.date;
                                const dateB = personB?.date?.includes("/")
                                    ? personB?.date.split("/")[0]
                                    : personB?.date;
                                new Date(personB.awardDate);

                                // Compare the dates to determine the order
                                return dateB - dateA;
                            }
                        );
                    }
                }
            },
        },
    };
</script>

<style lang="scss">
.winnerAwards {
    &__title {
        @media screen and(max-width:575px) {
            font-size: 18px !important;
        }
    }
}

.return {
    &__contents {
        &__btn {
            background: linear-gradient(to left, #50c299, #baf8b7);

            @media screen and(max-width:575px) {
                font-size: 15px !important;
            }
        }
    }
}
</style>
