<template>
    <div class="d-flex justify-content-between align-items-center">
        <BFormGroup class="my-2">
            <BFormCheckbox :modelValue="isChecked" @update:modelValue="$emit('change', name)" size="md" :class="{
                'selected-checkbox': isChecked,
            }">
                <div v-if="isChecked">
                    <strong>{{ name }}</strong>
                    <strong v-if="end">{{ `-${end}` }}</strong>
                </div>
                <div v-else>
                    <span>{{ name }}</span>
                    <span v-if="end">{{ `-${end}` }}</span>
                </div>
            </BFormCheckbox>
        </BFormGroup>
        <div>
            <BBadge :class="isChecked
                ? 'bg-success badge'
                : 'bg-white badge text-dark border border-1'">
                {{ length }}
            </BBadge>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    "name": String | Number,
    selectedCheckBox: {
        type: Array,
        default: () => []
    },
    end: String | Number,
    length: Number
});

const isChecked = computed(() => {
    return Array.isArray(props.selectedCheckBox) &&
        props.selectedCheckBox.some((obj) => obj.start === props.name);
});
</script>

<style lang="scss">
.filterCard {
    border-radius: 16px !important;
    margin-bottom: 20px;
}

.form-check-input:checked {
    background-color: #5bc89c !important;
    color: white !important;
    border: none;
}

.selected-badge {
    background-color: #5bc89c !important;
    color: white;
    border: none;
}

.bg-success {
    background-color: #5bc89c !important;
    color: white;
}

.badge {
    border-radius: 20px !important;
}
</style>
