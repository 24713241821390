<template>
    <div class="d-flex justify-content-center align-items-center home-card-container" v-if="Object.keys(this.golfClubData).length == 0">
        <Spinner />
    </div>
    <div class="home-card-main" v-else>
        <BRow class="d-flex justify-content-center align-items-center mt-3 mt-lg-5 home-card-row">
            <BCol v-for="(card, index) in cards" :key="index" class="col-12 col-lg-6 col-md-12">
                <HomeCard :image="card.image" :title="card.title" :index="index" :length="cards.length" />
            </BCol>
        </BRow>
    </div>
</template>

<script>
    import HomeCard from "../components/HomeCard.vue";
    import Spinner from "../components/Spinner.vue";
    import Header from "@/components/Header.vue";
    import trophy from "@/assets/imgs/trophy.png";
    export default {
        components: {
            HomeCard,
            Header,
            Spinner,
        },

        data() {
            return {
                searchterm: "",
                cards: [
                    {
                        image: null,
                        title: "",
                    },
                ],
                isMobile: false,
            };
        },
        watch: {
            golfClubData() {
                if (this.golfClubData) {
                    const { left_hand_honours_box_text, right_hand_honours_box_text } = this.golfClubData;
                    const leftCard = {
                        image: this.golfClubData?.split_screen_third_party_logo	 ? `${this.baseUrl}${this.golfClubData.split_screen_third_party_logo}` : trophy,
                        title: left_hand_honours_box_text || "Club Honours Board",
                    };
                    const rightCard = {
                        image: this.golfClubData?.split_screen_board_logo ? `${this.baseUrl}${this.golfClubData.split_screen_board_logo}` : trophy,
                        title: right_hand_honours_box_text || "Club Honours Board",
                    };

                    if (left_hand_honours_box_text && right_hand_honours_box_text) {
                        this.cards = [leftCard, rightCard];
                    } else if (left_hand_honours_box_text) {
                        this.cards = [leftCard];
                    } else if (right_hand_honours_box_text) {
                        this.cards = [rightCard];
                    } else if (this.golfClubData?.split_screen_third_party_logo	) {
                        this.cards = [leftCard];
                    } else {
                        this.cards = [
                            {
                                image: trophy,
                                title: "Club Honours Board",
                            }
                        ];
                    }
                }
            }
        },
        mounted() {
            if (this.golfClubData) {
                const { left_hand_honours_box_text, right_hand_honours_box_text } = this.golfClubData;
                const leftCard = {
                    image: this.golfClubData?.split_screen_third_party_logo	 ? `${this.baseUrl}${this.golfClubData.split_screen_third_party_logo}` : trophy,
                    title: left_hand_honours_box_text || "Club Honours Board",
                };
                const rightCard = {
                    image: this.golfClubData?.split_screen_board_logo ? `${this.baseUrl}${this.golfClubData.split_screen_board_logo}` : trophy,
                    title: right_hand_honours_box_text || "Club Honours Board",
                };

                if (left_hand_honours_box_text && right_hand_honours_box_text) {
                    this.cards = [leftCard, rightCard];
                } else if (left_hand_honours_box_text) {
                    this.cards = [leftCard];
                } else if (right_hand_honours_box_text) {
                    this.cards = [rightCard];
                } else if (this.golfClubData?.split_screen_third_party_logo	) {
                    this.cards = [leftCard];
                } else {
                    this.cards = [
                        {
                            image: trophy,
                            title: "Club Honours Board",
                        }
                    ];
                }
            }
        },
    };
</script>

<style>
.home-card-container {
    min-height: calc(100dvh - 200px) !important;
    min-width: 100% !important;
}

.home-card-main {
    min-width: 100% !important;
    display: flex;
    height: calc(100vh - 12rem) !important;
    justify-content: center;
}

.home-card-row {
    width: 100% !important;
}
</style>
